import PropTypes from "prop-types";
import React from "react";

import Layout from "#components/Layout";
import { HttpError } from "#utils";

import styles from "../pages-helpers/_error.module.css";

const TITLES = {
  404: "Sidan kunde inte hittas",
  500: "Internt serverfel",
  503: "Server otillgänglig",
  1404: "En resurs kunde inte hittas",
  1503: "En förfrågan misslyckades. Kontrollera din Internet-anslutning!",
};

const FALLBACK_TITLE = "Ett oväntat fel inträffade";

export default class Error extends React.Component {
  static propTypes = {
    statusCode: PropTypes.number,
  };

  static defaultProps = {
    statusCode: undefined,
  };

  static bareBones = true;

  static getInitialProps({ res, err, query }) {
    const status = getStatus(query, err, res);
    const noResponse = err != null && err.noResponse === true;
    const statusCode =
      status.code === 404 && !status.expect404
        ? 1404
        : noResponse
        ? typeof window === "undefined"
          ? 503
          : 1503
        : status.code;

    if (res != null) {
      // On the server, set the status code.
      // res.status(noResponse ? 503 : status.code != null ? status.code : 500);
    }

    return { statusCode };
  }

  render() {
    const { statusCode } = this.props;

    const title = TITLES[statusCode] || FALLBACK_TITLE;

    return (
      <Layout title={title} noindex>
        <div className={styles.root}>
          <p>
            {statusCode == null || Number.isNaN(statusCode)
              ? title
              : `${statusCode} – ${title}`}
          </p>
        </div>
      </Layout>
    );
  }
}

function getStatus(query, err, res) {
  if (query.status != null) {
    return { code: Number(query.status), expect404: true };
  }

  if (err != null) {
    if (typeof err.status === "number") {
      return { code: err.status, expect404: err instanceof HttpError };
    }

    if (err.response != null && typeof err.response.status === "number") {
      return { code: err.response.status, expect404: false };
    }
  }

  if (res != null) {
    return { code: res.statusCode, expect404: true };
  }

  return { code: undefined, expect404: false };
}
